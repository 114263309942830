import { MaterialSymbolsChevronRight } from '@/assets/icons/mine/MaterialSymbolsChevronRight';
import BaseTopNav from '@/components/base/baseTopNav';
import FeaturesSettingTopTitle from '@/components/features/featuresSettingTopTitle';
import PageEnum from '@/enums/pageEnum';
import { history } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import {useModel} from "@@/exports";

export default function Mine() {
  const { t } = useTranslation();
  const {gotoGlobalCustomerService} = useModel("imSocket")

  const cardArr: any = [
    {
      key: '1',
      label: t('需要帮助？点击查看帮助文档'),
      onclick: () => {
        history.push(PageEnum.HELPARTICLE);
      },
    },
    {
      key: '2',
      label: t('功能反馈'),
      onclick: () => {
        history.push(PageEnum.HELPFEEDBACK);
      },
    },
    {
      key: '3',
      label: t('在线客服'),
      onclick: () => {
        gotoGlobalCustomerService();
      },
    },
  ].filter((i) => !!i);

  return (
    <div>
      <BaseTopNav />
      {/*顶部标题*/}
      <FeaturesSettingTopTitle
        title={t('帮助 & 支持')}
        description={t('解答疑问，或提出反馈')}
      />
      <div className="px-4 py-1">
        {cardArr?.map((item: any, index: number) => {
          return (
            <div
              key={item.key}
              onClick={() => {
                item?.onclick();
              }}
              className="py-3 cursor-pointer"
            >
              <div className="flex justify-between">
                <div className=" text-base">{item.label}</div>

                <MaterialSymbolsChevronRight className="text-xl" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
